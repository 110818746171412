import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabaseClient";
import CameraCapture from "../../components/CameraCapture";

function EditFormPage() {
  const { formId } = useParams();
  const navigate = useNavigate();
  const [formName, setFormName] = useState("");
  const [fields, setFields] = useState([]);
  const [newFieldType, setNewFieldType] = useState("text");

  const fetchForm = useCallback(async () => {
    const { data, error } = await supabase
      .from("forms")
      .select("*")
      .eq("id", formId)
      .single();

    if (error) {
      alert("Error loading form: " + error.message);
    } else {
      setFormName(data.name);
      setFields(data.fields || []);
    }
  }, [formId]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  // Field management functions
  const updateField = (index, key, value) => {
    const updatedFields = [...fields];
    updatedFields[index][key] = value;
    setFields(updatedFields);
  };

  const handleOptionChange = (index, value) => {
    const newFields = [...fields];
    newFields[index].options = value.split(",");
    setFields(newFields);
  };

  const updateCondition = (index, key, value) => {
    const updated = [...fields];
    updated[index].condition = {
      ...(updated[index].condition || {}),
      [key]: value,
    };
    setFields(updated);
  };

  const addField = () => {
    setFields([
      ...fields,
      {
        label: "",
        type: newFieldType,
        options: ["dropdown", "radio", "checkbox"].includes(newFieldType)
          ? ["Option 1"]
          : [],
        condition: null,
        imageURL: null,
        required: false,
      },
    ]);
  };

  const removeField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  const handleSave = async () => {
    if (!formName || fields.length === 0) {
      alert("Form name and at least one field is required!");
      return;
    }

    // Validate required fields
    for (const field of fields) {
      if (field.required && !field.label) {
        alert(`Field "${field.label}" is required but has no label`);
        return;
      }
    }

    const { error } = await supabase
      .from("forms")
      .update({ name: formName, fields })
      .eq("id", formId);

    if (error) {
      alert("Error saving form: " + error.message);
    } else {
      navigate("/admin");
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>Edit Form</h2>
      <div style={{ marginBottom: 20 }}>
        <label>
          <strong>Form Name: </strong>
          <input
            type="text"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
            style={{ padding: 5 }}
          />
        </label>
      </div>

      <div style={{ marginBottom: 20 }}>
        <label>
          <strong>Add New Field: </strong>
          <select
            value={newFieldType}
            onChange={(e) => setNewFieldType(e.target.value)}
            style={{ padding: 5, marginRight: 10 }}
          >
            <option value="text">Text</option>
            <option value="textarea">Textarea</option>
            <option value="dropdown">Dropdown</option>
            <option value="radio">Radio</option>
            <option value="checkbox">Checkbox</option>
            <option value="file">File Upload</option>
            <option value="camera">Camera</option>
          </select>
        </label>
        <button onClick={addField}>Add Field</button>
      </div>

      <table
        border="1"
        cellPadding="10"
        style={{ width: "100%", marginBottom: 20 }}
      >
        <thead>
          <tr>
            <th>Label</th>
            <th>Type</th>
            <th>Required</th>
            <th>Options</th>
            <th>Condition</th>
            <th>Camera</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, i) => (
            <tr key={i}>
              {/* Label */}
              <td>
                <input
                  type="text"
                  value={field.label}
                  onChange={(e) => updateField(i, "label", e.target.value)}
                  style={{ width: "100%" }}
                />
              </td>

              {/* Type */}
              <td>
                <select
                  value={field.type}
                  onChange={(e) => updateField(i, "type", e.target.value)}
                >
                  <option value="text">Text</option>
                  <option value="textarea">Textarea</option>
                  <option value="dropdown">Dropdown</option>
                  <option value="radio">Radio</option>
                  <option value="checkbox">Checkbox</option>
                  <option value="file">File</option>
                  <option value="camera">Camera</option>
                </select>
              </td>

              {/* Required */}
              <td style={{ textAlign: "center" }}>
                <input
                  type="checkbox"
                  checked={field.required || false}
                  onChange={(e) => updateField(i, "required", e.target.checked)}
                />
              </td>

              {/* Options */}
              <td>
                {["dropdown", "radio", "checkbox"].includes(field.type) ? (
                  <input
                    type="text"
                    placeholder="Option1, Option2,..."
                    value={field.options?.join(", ") || ""}
                    onChange={(e) => handleOptionChange(i, e.target.value)}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <span>-</span>
                )}
              </td>

              {/* Condition */}
              <td>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 5 }}
                >
                  <select
                    value={field.condition?.field || ""}
                    onChange={(e) =>
                      updateCondition(i, "field", e.target.value)
                    }
                  >
                    <option value="">-- None --</option>
                    {fields
                      .filter((_, idx) => idx !== i)
                      .map((f, idx) => (
                        <option key={idx} value={f.label}>
                          {f.label}
                        </option>
                      ))}
                  </select>
                  <input
                    type="text"
                    placeholder="Value"
                    value={field.condition?.value || ""}
                    onChange={(e) =>
                      updateCondition(i, "value", e.target.value)
                    }
                  />
                </div>
              </td>

              {/* Camera */}
              <td>
                {field.type === "camera" && (
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    <CameraCapture
                      onUpload={(file) => {
                        updateField(i, "imageURL", URL.createObjectURL(file));
                      }}
                    />
                    {field.imageURL && (
                      <a href={field.imageURL} target="_blank" rel="noreferrer">
                        View
                      </a>
                    )}
                  </div>
                )}
              </td>

              {/* Action */}
              <td>
                <button onClick={() => removeField(i)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button
        onClick={handleSave}
        style={{ padding: "10px 20px", fontSize: "1rem" }}
      >
        Save Changes
      </button>
    </div>
  );
}

export default EditFormPage;
