// src/AdminPage.js
import React, { useEffect, useState, useCallback } from "react";
import { supabase } from "../../lib/supabaseClient";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";

function AdminPage() {
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();

  const fetchForms = useCallback(async () => {
    const { data, error } = await supabase
      .from("forms")
      .select("*")
      .order("created_at", { ascending: false });

    if (error) {
      alert("Error fetching forms: " + error.message);
    } else {
      setForms(data);
    }
  }, []);

  const checkAuthAndFetch = useCallback(async () => {
    const {
      data: { session },
    } = await supabase.auth.getSession();

    if (!session) {
      navigate("/login");
      return;
    }

    fetchForms();
  }, [navigate, fetchForms]);

  useEffect(() => {
    checkAuthAndFetch();
  }, [checkAuthAndFetch]);

  const handleDelete = async (formId) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this form?"
    );
    if (!confirm) return;

    const { error } = await supabase.from("forms").delete().eq("id", formId);

    if (error) {
      alert("Error deleting form: " + error.message);
    } else {
      alert("Form deleted.");
      fetchForms(); // Refresh list
    }
  };

  const handleCopyLink = (formId) => {
    const url = `${window.location.origin}/form/${formId}`;
    navigator.clipboard.writeText(url);
    alert("Link copied to clipboard!");
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate("/login");
  };

  return (
    <div className="admin-container">
      <div className="admin-header">
        <h2 className="admin-title">Admin Dashboard</h2>
        <button className="admin-button logout" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <button
        className="admin-button primary"
        onClick={() => navigate("/create-form")}
      >
        Create New Form
      </button>

      <div className="forms-list">
        {forms.length === 0 ? (
          <p className="forms-empty">No forms yet.</p>
        ) : (
          <div>
            {forms.map((form) => (
              <div key={form.id} className="form-item">
                <h3 className="form-title">{form.name || "(Untitled Form)"}</h3>
                <div className="form-actions">
                  <button
                    className="admin-button button-view"
                    onClick={() => navigate(`/form/${form.id}`)}
                  >
                    View Form
                  </button>
                  <button
                    className="admin-button button-copy"
                    onClick={() => handleCopyLink(form.id)}
                  >
                    Copy Link
                  </button>
                  <button
                    className="admin-button button-responses"
                    onClick={() => navigate(`/form-data/${form.id}`)}
                  >
                    View Responses
                  </button>
                  <button
                    className="admin-button button-edit"
                    onClick={() => navigate(`/edit-form/${form.id}`)}
                  >
                    Edit
                  </button>
                  <button
                    className="admin-button button-delete"
                    onClick={() => handleDelete(form.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminPage;
