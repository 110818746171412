import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../../lib/supabaseClient";
import "./FormResponses.css";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

function FormDataPage() {
  const { formId } = useParams();
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const fetchResponses = useCallback(async () => {
    setLoading(true);
    setErrorMsg("");
    const { data, error } = await supabase
      .from("responses")
      .select("*")
      .eq("form_id", formId)
      .order("created_at", { ascending: false });

    if (error) {
      setErrorMsg("Error fetching responses: " + error.message);
    } else {
      setResponses(data);
    }
    setLoading(false);
  }, [formId]);

  useEffect(() => {
    fetchResponses();
  }, [fetchResponses]);

  const generateSignedUrl = async (filePath) => {
    const { data, error } = await supabase.storage
      .from("platipus-uploads")
      .createSignedUrl(filePath, 60 * 5);

    if (error) {
      console.error("Error generating signed URL:", error.message);
      return null;
    }

    return data.signedUrl;
  };

  const handleDownload = async (filePath) => {
    const signedUrl = await generateSignedUrl(filePath);
    if (signedUrl) {
      const fileName = filePath.split("/").pop();
      saveAs(signedUrl, fileName);
    } else {
      alert("Failed to generate download link.");
    }
  };

  const handleDelete = async (responseId) => {
    if (!window.confirm("Are you sure you want to delete this response?"))
      return;

    const { error } = await supabase
      .from("responses")
      .delete()
      .eq("id", responseId);

    if (error) {
      alert("Error deleting response: " + error.message);
    } else {
      setResponses((prev) => prev.filter((res) => res.id !== responseId));
    }
  };

  const handleExport = async (format) => {
    const exportData = await Promise.all(
      responses.map(async (res) => {
        const flat = {};
        for (const [key, val] of Object.entries(res.answers || {})) {
          if (typeof val === "string" && val.startsWith("uploads/")) {
            const signedUrl = await generateSignedUrl(val);
            flat[key] = signedUrl || val;
          } else {
            flat[key] = val;
          }
        }
        return flat;
      })
    );

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Responses");

    if (format === "xlsx") {
      XLSX.writeFile(workbook, "form_responses.xlsx");
    } else {
      XLSX.writeFile(workbook, "form_responses.csv", { bookType: "csv" });
    }
  };

  const fields = Object.keys(responses[0]?.answers || {});

  return (
    <div className="form-data-container">
      <div className="form-data-header">
        <h2 className="form-data-title">Form Responses</h2>
        {responses.length > 0 && (
          <div className="export-controls">
            <button
              className="export-button"
              onClick={() => handleExport("xlsx")}
            >
              Export to Excel
            </button>
            <button
              className="export-button csv"
              onClick={() => handleExport("csv")}
            >
              Export to CSV
            </button>
          </div>
        )}
      </div>

      {loading ? (
        <div className="loading-state">Loading responses...</div>
      ) : errorMsg ? (
        <div className="error-state">{errorMsg}</div>
      ) : responses.length === 0 ? (
        <div className="empty-state">No responses yet.</div>
      ) : (
        <div className="table-container">
          <table className="responses-table">
            <thead>
              <tr>
                {fields.map((field, i) => (
                  <th key={i}>{field}</th>
                ))}
                <th>Submitted At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {responses.map((response) => (
                <tr key={response.id}>
                  {fields.map((field, j) => {
                    const value = response.answers[field];
                    const isFile =
                      typeof value === "string" && value.startsWith("uploads/");

                    return (
                      <td key={j}>
                        {isFile ? (
                          <button
                            className="download-button"
                            onClick={() => handleDownload(value)}
                          >
                            Download File
                          </button>
                        ) : (
                          String(value)
                        )}
                      </td>
                    );
                  })}
                  <td className="timestamp-cell">
                    {new Date(response.created_at).toLocaleString()}
                  </td>
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDelete(response.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default FormDataPage;
